import { NextRouter, useRouter } from 'next/router'
import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import routes from '../config/routes'
import { NextSeo } from 'next-seo'
import {
  extractLocaleFromURL,
  getLocalePathFromPathname,
} from '../lib/utils/language'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Layout from '../components/Layout'

function Page404() {
  const router: NextRouter = useRouter()
  const { t } = useTranslation('404')

  useEffect(() => {
    const currentLocale =
      router.query.locale || extractLocaleFromURL(router.asPath)
    const path = getLocalePathFromPathname(
      router.pathname,
      currentLocale as string,
    )
    if (router.asPath !== path) {
      router.replace(path)
    }
  }, [router])

  return (
    <>
      <NextSeo
        title={t('seo.title') || ''}
        description={t('seo.description') || ''}
        noindex
      />
      <div className="bg-featured-pattern md:bg-featured-pattern relative isolate flex h-screen bg-cover bg-center">
        <div className="m-auto  max-w-7xl px-6  text-center lg:px-8">
          <p className="text-base font-semibold leading-8 text-neutralBlack">
            404
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutralBlack sm:text-5xl">
            {t('title')}
          </h1>
          <p className="mt-4 text-base text-neutralBlack/70 sm:mt-6">
            {t('text')}
          </p>
          <div className="mt-10 flex justify-center">
            <a
              href={routes.index}
              className="text-sm font-semibold leading-7 text-neutralBlack"
            >
              <span aria-hidden="true">&larr;</span> {t('cta')}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

Page404.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>
}

export default Page404

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
        'navbar',
        'footer',
        '404',
      ])),
    },
  }
}
